import React from 'react';
import './ErrorComponent.scss';
import ErrorHat from '../content/error-hat.svg';


export default function ErrorComponent() {
    return (
        <div className='error-component'>
            <div className='error-content'>
                <h3>Somethin ain&apos;t right</h3>
                <ErrorHat />
                <h3>Try refreshing the page</h3>
            </div>
        </div>
    )
}