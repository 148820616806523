import React, { useEffect, useRef, useState } from 'react';
import { Wallet } from '../code/Wallet';
import './SingleAssetFarm.scss';
import { TextField } from '@mui/material';
import { BIG_ZERO } from '../code/types';
import { formatTokenValue, formatValue, parseBigNumber } from '../code/globals';
import { appConfig } from '../code/appConfig';
import { erc20Allowance, erc20BalanceOf } from '../code/contracts/Erc20';
import { LoadingButton } from '@mui/lab';
import { GiddyFarm } from '../code/contracts/GiddyFarm';
import { ethers } from 'ethers';

interface SingleAssetFarmProps {
  account: string;
  wallet: Wallet;
  displaySuccess(message: string): void;
  displayError(message: string): void;
}

export default function SingleAssetFarm({account, wallet, displaySuccess, displayError}:SingleAssetFarmProps) {
  const giddyFarm = useRef(new GiddyFarm(wallet));
  const [updateCount, setUpdateCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [contactStaked, setContractStaked] = useState(BIG_ZERO);
  const [rewardsPerSecond, setRewardsPerSecond] = useState(BIG_ZERO);
  const [rewardsRemaining, setRewardsRemaining] = useState(BIG_ZERO);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [userGiddy, setUserGiddy] = useState(BIG_ZERO);
  const [userStaked, setUserStaked] = useState(BIG_ZERO);
  const [userUsdc, setUserUsdc] = useState(BIG_ZERO);
  const [userRewards, setUserRewards] = useState(BIG_ZERO);
  const [amount, setAmount] = useState(BIG_ZERO);
  const [amountText, setAmountText] = useState('');
  const [amountError, setAmountError] = useState('');
  const [harvestText, setHarvestText] = useState('Harvest');
  const [depositText, setDepositText] = useState('Deposit');
  const [withdrawText, setWithdrawText] = useState('Withdraw');

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateCount(Date.now());
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    erc20BalanceOf(appConfig.giddy, appConfig.giddyFarm).then(value => {
      setContractStaked(value);
    });
    giddyFarm.current.rewardsPerSecond().then(value => {
      setRewardsPerSecond(value);
    });
    giddyFarm.current.contractRewards().then(value => {
      setRewardsRemaining(value[0]);
      setSecondsRemaining(value[1].toNumber());
    });
  }, [giddyFarm, updateCount]);

  useEffect(() => {
    if (account) {
      erc20BalanceOf(appConfig.giddy, account).then(value => {
        setUserGiddy(value);
      });
      erc20BalanceOf(appConfig.usdc, account).then(value => {
        setUserUsdc(value);
      });
      giddyFarm.current.userStaked(account).then(value => {
        setUserStaked(value);
      });
      giddyFarm.current.userRewards(account).then(value => {
        setUserRewards(value);
      });
    }
  }, [account, giddyFarm, updateCount]);

  function amountChange(value: string) {
    setAmountError('');
    setAmount(BIG_ZERO);
    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;
    setAmountText(value);
    if (value.length > 0) {
      const amountValue = parseBigNumber(value, 18);
      if (!amountValue.gt(BIG_ZERO)) {
        setAmountError('Invalid Value');
      }
      else {
        setAmount(amountValue);
      }
    }
  }

  async function harvestClicked() {
    setLoading(true);
    setHarvestText('Pending');
    const result = await giddyFarm.current.harvest(BIG_ZERO);
    if (!ethers.utils.isHexString(result)) {
      displayError(result);
    }
    else {
      const success = await giddyFarm.current.wallet.waitForTransactionStatus(result, 1, 1000, 300);
      if (!success) {
        displayError('Unstake unsuccessful, transaction failed');
      }
      else {
        displaySuccess('Successfully harvested');
        setUpdateCount(Date.now());
      }
    }
    setHarvestText('Harvest');
    setLoading(false);
  }

  async function depositClicked() {
    setLoading(true);
    setDepositText('Signing');
    const currentAllowance = await erc20Allowance(appConfig.giddy, account, appConfig.giddyFarm);
    const signedAuthResult = await giddyFarm.current.wallet.generateSignedAuthGiddy(appConfig.giddyFarm, amount, currentAllowance);
    if (signedAuthResult[0]) {
      displayError('Error signing approval, ' + signedAuthResult[0]);
    }
    else {
      setDepositText('Pending');
      const result = await giddyFarm.current.deposit(signedAuthResult[1], signedAuthResult[2], ethers.constants.WeiPerEther);
      if (!ethers.utils.isHexString(result)) {
        displayError(result);
      }
      else {
        const success = await giddyFarm.current.wallet.waitForTransactionStatus(result, 1, 1000, 300);
        if (!success) {
          displayError('Stake unsuccessful, transaction failed');
        }
        else {
          displaySuccess('Successfully staked ' + formatTokenValue(amount, 18) + ' Giddy');
          amountChange("");
          setUpdateCount(Date.now());
        }
      }
    }
    setDepositText('Deposit');
    setLoading(false);
  }

  async function withdrawClicked() {
    setLoading(true);
    setWithdrawText('Pending');
    const result = await giddyFarm.current.withdraw(amount, ethers.constants.WeiPerEther);
    if (!ethers.utils.isHexString(result)) {
      displayError(result);
    }
    else {
      const success = await giddyFarm.current.wallet.waitForTransactionStatus(result, 1, 1000, 300);
      if (!success) {
        displayError('Unstake unsuccessful, transaction failed');
      }
      else {
        displaySuccess('Successfully unstaked ' + formatTokenValue(amount, 18) + ' Giddy');
        amountChange("");
        setUpdateCount(Date.now());
      }
    }
    setWithdrawText('Withdraw');
    setLoading(false);
  }

  return (
    <div className='single-asset-farm'>
      <h2>Giddy Single Asset USDC Farm</h2>
      <div className='farm-info'>
        <div>
          <span className='farm-info-header'>Contract Staked GIDDY: </span>{formatTokenValue(contactStaked, 18, false)}
        </div>
        <div>
          <span className='farm-info-header'>USDC Per Second: </span>{formatTokenValue(rewardsPerSecond, 6, false)}
        </div>
        <div>
          <span className='farm-info-header'>Remaining Rewards USDC: </span>{formatTokenValue(rewardsRemaining, 6, true)}
        </div>
        <div>
          <span className='farm-info-header'>Days Remaining: </span>{formatValue(secondsRemaining / 86400)}
        </div>
        <div>
          <span className='farm-info-header'>User Balance GIDDY: </span>{formatTokenValue(userGiddy, 18, false)}
        </div>
        <div>
          <span className='farm-info-header'>User Staked GIDDY: </span>{formatTokenValue(userStaked, 18, false)}
        </div>
        <div>
          <span className='farm-info-header'>User Balance USDC: </span>{formatTokenValue(userUsdc, 6, false)}
        </div>
        <div>
          <span className='farm-info-header'>User Pending Rewards USDC: </span>{formatTokenValue(userRewards, 6, false)}
        </div>
      </div>
      <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={loading}
          loading={harvestText !== "Harvest"}
          loadingPosition="end"
          onClick={harvestClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}>
          {harvestText}
          </LoadingButton>
      <TextField
        fullWidth
        label={"Amount"} 
        value={amountText} 
        disabled={loading}
        onChange={(e) => amountChange(e.target.value)} 
        error={amountError.length > 0} 
        helperText={amountError} />
      <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={amount.eq(BIG_ZERO) || loading}
          loading={depositText !== "Deposit"}
          loadingPosition="end"
          onClick={depositClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}>
          {depositText}
          </LoadingButton>
        <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={amount.eq(BIG_ZERO) || loading}
          loading={withdrawText !== "Withdraw"}
          loadingPosition="end"
          onClick={withdrawClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}>
          {withdrawText}
          </LoadingButton>
    </div>
  );
}
