import { appConfig } from "../appConfig";
import ABI from './GiddyVaultV2.json';
import { BigNumber, ethers } from "ethers";
import { Wallet } from "../Wallet";
import { VaultAuth, SwapInfo, VaultInfo, YAGlobalSettings, UsdcAuth } from "../types";
import { BIG_ZERO } from "../types";

export class GiddyVaultV2 {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;
  private feeMultiplier = 20;

  public wallet: Wallet;
  public info: VaultInfo;
  
  constructor(wallet: Wallet, info: VaultInfo) {
    this.wallet = wallet;
    this.info = info;
    this.contract = new ethers.Contract(info.address, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async getStrategy(): Promise<string> {
    try {
      return await this.contract.strategy();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getStrategy', error);
    }
    return "";
  }

  public async getContractShares(): Promise<BigNumber> {
    try {
      return await this.contract.getContractShares();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getContractShares', error);
    }
    return ethers.constants.Zero;
  }

  public async getContractBalance(): Promise<BigNumber> {
    try {
      return await this.contract.getContractBalance();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getContractBalance', error);
    }
    return ethers.constants.Zero;
  }

  public async getContractRewards(): Promise<BigNumber[]> {
    try {
      return await this.contract.getContractRewards();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getContractRewards', error);
    }
    return [];
  }

  public async getUserShares(address: string): Promise<BigNumber> {
    try {
      return await this.contract.getUserShares(address);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getUserShares', error);
    }
    return ethers.constants.Zero;
  }

  public async getUserBalance(address: string): Promise<BigNumber> {
    try {
      return await this.contract.getUserBalance(address);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getUserBalance', error);
    }
    return ethers.constants.Zero;
  }

  public async sharesToValue(shares: BigNumber): Promise<BigNumber> {
    try {
      return await this.contract.sharesToValue(shares);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.sharesToValue', error);
    }
    return ethers.constants.Zero;
  }

  public async getNativeToken(): Promise<string> {
    try {
      return await this.contract.getNativeToken();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getNativeToken', error);
    }
    return "";
  }

  public async getRewardTokens(): Promise<string[]> {
    try {
      return await this.contract.getRewardTokens();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getRewardTokens', error);
    }
    return [];
  }

  public async getDepositTokens(): Promise<string[]> {
    try {
      return await this.contract.getDepositTokens();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getDepositTokens', error);
    }
    return [];
  }

  public async getDepositRatios(): Promise<BigNumber[]> {
    try {
      return await this.contract.getDepositRatios();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getDepositRatios', error);
    }
    return [];
  }

  public async getWithdrawTokens(): Promise<string[]> {
    try {
      return await this.contract.getWithdrawTokens();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getWithdrawTokens', error);
    }
    return [];
  }

  public async getWithdrawAmounts(staked: BigNumber): Promise<BigNumber[]> {
    try {
      return await this.contract.getWithdrawAmounts(staked);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getWithdrawAmounts', error);
    }
    return [];
  }

  public async testValidate(vaultAuth: VaultAuth): Promise<string> {
    try {
      return await this.contract.testValidate(vaultAuth);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.testValidate', error);
    }
    return '';
  }

  public async compound(vaultAuth: VaultAuth): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.compound(vaultAuth);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.compound(vaultAuth, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.compound', error);
      return error.message;
    }
  }

  public async depositSingle(vaultAuth: VaultAuth): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.depositSingle(vaultAuth);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.depositSingle(vaultAuth, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.depositSingle', error);
      return error.message;
    }
  }

  public async depositUsdc(usdcAuth: UsdcAuth, vaultAuth: VaultAuth): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.depositUsdc(usdcAuth, vaultAuth);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.depositUsdc(usdcAuth, vaultAuth, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.depositUsdc', error);
      return error.message;
    }
  }

  public async depositGiddy(giddyAuth: any, giddySig: string, vaultAuth: VaultAuth): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.depositGiddy(giddyAuth, giddySig, vaultAuth);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.depositGiddy(giddyAuth, giddySig, vaultAuth, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.depositGiddy', error);
      return error.message;
    }
  }

  public async withdrawAuth(vaultAuth: VaultAuth): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.withdrawAuth(vaultAuth);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.withdrawAuth(vaultAuth, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.withdraw', error);
      return error.message;
    }
  }

  public async getGlobalSettings(): Promise<YAGlobalSettings> {
    try {
      return await this.contract.getGlobalSettings();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.getGlobalSettings', error);
    }
    return { feeAccount: "", earningsFee: ethers.constants.Zero } as YAGlobalSettings;
  }

  public async generateSignedUsdcAuth(owner: string, amount: BigNumber): Promise<UsdcAuth> {
    try {
      if (this.connectedContract) {
        const domain = {
          name: "USD Coin (PoS)",
          version: "1",
          verifyingContract: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          salt: "0x0000000000000000000000000000000000000000000000000000000000000089",
        };
  
        const types = {
          ApproveWithAuthorization: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "validAfter", type: "uint256" },
            { name: "validBefore", type: "uint256" },
            { name: "nonce", type: "bytes32" },
          ]
        };

        const signAuth = {
          owner:  owner,
          spender: this.info.address,
          value: amount,
          validAfter: BIG_ZERO,
          validBefore: BigNumber.from(Math.floor(Date.now() / 1000) + 3600),
          nonce: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
        }
  
        const signerAny: any = this.connectedContract.signer;
        const signature: string = await signerAny._signTypedData(domain, types, signAuth);

        const usdcAuth: UsdcAuth = {
          owner:  signAuth.owner,
          spender: signAuth.spender,
          value: signAuth.value,
          validAfter: signAuth.validAfter,
          validBefore: signAuth.validBefore,
          nonce: signAuth.nonce,
          v: "0x" + signature.slice(130, 132),
          r: signature.slice(0, 66),
          s: "0x" + signature.slice(66, 130),
        }
        return usdcAuth;
      }
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.generateSignedVaultAuth', error);
    }
    return {} as UsdcAuth;
  }

  public async generateSignedVaultAuth(amount: BigNumber, fap: BigNumber, fapIndex: number, depositSwaps: SwapInfo[], compoundSwaps: SwapInfo[]): Promise<VaultAuth> {
    try {
      if (this.connectedContract) {
        const domain = {
          name: this.info.name,
          version: '1.0',
          chainId: appConfig.network.chainId,
          verifyingContract: this.info.address,
        };
  
        const types = {
          VaultAuth: [
            { name: "nonce", type: "bytes32" },
            { name: "deadline", type: "uint256" },
            { name: "amount", type: "uint256" },
            { name: "fap", type: "uint256" },
            { name: "fapIndex", type: "uint256" },
            { name: "data", type: "bytes[]" },
          ]
        };

        const signAuth = {
          nonce: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
          deadline: BigNumber.from(Math.floor(Date.now() / 1000) + 500),
          amount: amount,
          fap: fap,
          fapIndex: BigNumber.from(fapIndex),
          data: depositSwaps.map(value => value.data).concat(compoundSwaps.map(value => value.data)),
        }

        const signerAny: any = this.connectedContract.signer;
        const signature: string = await signerAny._signTypedData(domain, types, signAuth);

        const vaultAuth: VaultAuth = {
          signature: signature,
          nonce: signAuth.nonce,
          deadline: signAuth.deadline,
          amount: signAuth.amount,
          fap: signAuth.fap,
          fapIndex: signAuth.fapIndex,
          depositSwaps: depositSwaps,
          compoundSwaps: compoundSwaps,
        }

        vaultAuth.signature = signature;
        return vaultAuth;
      }
    }
    catch (error: any) {
      console.error('GIDDY.GiddyVaultV2.generateSignedVaultAuth', error);
    }
    return {} as VaultAuth;
  }
}