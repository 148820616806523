import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { erc20BalanceOf } from '../code/contracts/Erc20';
import './TokenStake.scss';
import { formatTokenValue, parseBigNumber } from '../code/globals';
import { BIG_ZERO, TokenInfo } from '../code/types';

interface TokenStakeProps {
  account: string;
  token: TokenInfo;
  onApprove(token: string, amount: BigNumber): Promise<boolean>;
  onStake(token: string, amount: BigNumber): Promise<boolean>;
}

export default function TokenStake({account, token, onApprove, onStake}: TokenStakeProps) {
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(BIG_ZERO);

  const [amountText, setAmountText] = useState('');
  const [amountError, setAmountError] = useState('');
  const [approved, setApproved] = useState(false);

  const [buttonText, setButtonText] = useState('Approve');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (account) {
      erc20BalanceOf(token.address, account).then((balance) => {
        setBalance(balance);
      });
    }
    else {
      setBalance(BIG_ZERO);
    }
    setAmountError('');
    setButtonDisabled(true);
    setButtonText('Approve');
    setApproved(false);
    setAmountText('');
  }, [account, token]);

  function amountChanged(value: string) {
    setAmountError('');
    setButtonDisabled(true);
    setButtonText('Approve');
    setApproved(false);

    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;
    setAmountText(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, token.decimals);
      if (!amount.gt(ethers.constants.Zero)) {
        setAmountError('Invalid value');
      }
      else {
        if (amount.gt(balance)) {
          setAmountError("Amount exceeds balance");
        }
        else {
          setButtonDisabled(false);
        }
      }
    }
  }

  function maxClicked() {
    amountChanged(formatTokenValue(balance, token.decimals, false));
  }

  async function buttonClicked() {
    if (account) {
      setLoading(true);
      const amount = parseBigNumber(amountText, token.decimals);
      if (!approved) {
        setButtonText('Confirming');
        if (await onApprove(token.address, amount)) {
          setButtonText('Stake');
          setApproved(true);
        }
        else {
          setButtonText('Approve');
        }
      }
      else {
        setButtonText('Confirming');
        if (await onStake(token.address, amount)) {
          setButtonText('Success');
          amountChanged('');
        }
        else {
          setButtonText('Stake');
        }
      }
      setLoading(false);
    }
  }

  return (
    <div className='token-stake'>
      <div className='amount-box'>
        <TextField
          fullWidth
          label='Amount'
          value={amountText} 
          onChange={(e) => amountChanged(e.target.value)} 
          error={amountError.length > 0} 
          helperText={amountError}
          disabled={loading} />
        <Button sx={{height: 56}} onClick={maxClicked} disabled={loading}>Max</Button>
      </div>
      <div className='balance'>Balance: {formatTokenValue(balance, token.decimals, true)}</div>
      <LoadingButton 
        variant="contained" 
        fullWidth 
        disabled={buttonDisabled}
        loading={loading}
        loadingPosition="end"
        onClick={buttonClicked}
        sx={{
          ':disabled': {
            'background': '#E2DFD9',
            'color': '#FFF'
          }
        }}
        >
        {buttonText}
      </LoadingButton>
    </div>
  );
}
