import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@sentry/react';
import { giddyTheme } from './code/GiddyTheme';
import { client } from './code/graphql';
import ErrorComponent from './components/ErrorComponent';
import './fonts.css'
import './index.scss';
import App from './components/App';

ReactDOM.render(
  <ErrorBoundary fallback={<ErrorComponent />}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={giddyTheme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </ErrorBoundary>
  , document.getElementById("root")
);