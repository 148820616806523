
import { BigNumber } from 'ethers';
import { appConfig } from './appConfig';

export type OneInchToken = {
  symbol: string;
  name: string;
  address: string;
  decimals: number;
};

export type OneInchSwapResponse = {
  fromToken: OneInchToken;
  toToken: OneInchToken;
  fromTokenAmount: string;
  toTokenAmount: string;
  protocols: string;
  tx: {
    from: string;
    to: string;
    data: string;
    value: string;
    gasPrice: string;
    gas: string;
  };
};

export type OneInchSwapQuoteResponse = {
  fromToken: OneInchToken;
  toToken: OneInchToken;
  fromTokenAmount: string;
  toTokenAmount: string;
  protocols: string;
  estimatedGas: number;
};

export async function oneInchQuote<T>(from: string, to: string, amount: BigNumber): Promise<T> {
  const response = await fetch(appConfig.oneInchUrl + "quote?fromTokenAddress=" + from + "&toTokenAddress=" + to + "&amount=" + amount);
  if (response.ok) {
    return (await response.json()) as T;
  }
  console.error("oneInchQuote: " + response.status);
  return {} as T;
}

export async function oneInchSwap<T>(from: string, to: string, amount: BigNumber, account: string, receiver: string, slippage: number): Promise<T> {
  const response = await fetch(appConfig.oneInchUrl + "swap?disableEstimate=true&fromTokenAddress=" + from + "&toTokenAddress=" + to + "&amount=" + amount + "&fromAddress=" + account + "&destReceiver=" + receiver + "&slippage=" + slippage);
  if (response.ok) {
    return (await response.json()) as T;
  }
  console.error("oneInchSwap: " + response.status);
  return {} as T;
}