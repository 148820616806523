import React, { useEffect, useState } from 'react';
import './ConnectDialog.scss';
import Dialog from '@mui/material/Dialog';
import { WalletType } from '../code/types';
import { Button, IconButton, SvgIcon } from '@mui/material';
import MetaMaskIcon from '../content/icon-metamask.svg';
import WalletConnectIcon from '../content/icon-walletconnect.svg';
import CoinbaseIcon from '../content/icon-coinbase.svg';
import CloseIcon from 'mdi-react/CloseIcon';
import {useRive, Layout, LayoutParameters, Alignment, Fit } from 'rive-react'
import walletAnimation from '../content/wallet-animation.riv'

interface ConnectDiaglogProps {
  walletType: WalletType;
  isOpen: boolean;
  onClose(): void;
  onSelect(value: WalletType): void;
}

export default function ConnectDiaglog({walletType, isOpen, onClose, onSelect}:ConnectDiaglogProps) {
  const [showDisconnect, setShowDisconnect] = useState(false);
  const layoutParams: LayoutParameters = {
    fit: Fit.Contain,
    alignment: Alignment.Center
  }
  const { RiveComponent } = useRive({
    src: walletAnimation,
    autoplay: true,
    layout: new Layout(layoutParams)
  });

  useEffect(() => {
    if (isOpen) {
      setShowDisconnect(walletType !== WalletType.None);
    }
  }, [isOpen, walletType]);

  function walletClicked(e:React.MouseEvent<HTMLButtonElement>) {
    onSelect(e.currentTarget.value as WalletType);
  }

  return (
  <Dialog fullWidth maxWidth="xs" onClose={onClose} open={isOpen}>
    <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, }}>
      <CloseIcon />
    </IconButton>
    <div className='connect-dialog'>
      <RiveComponent className='coin-animation' />
      <h2 className="connect-dialog header">Connect Wallet</h2>
      <p className="legal-stuff">By connecting a wallet, you agree to Giddy’s <a href="https://giddy.co/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a> and acknowledge that you have read and understand the Giddy <a href="https://giddy.co/privacy-notice/"  target="_blank" rel="noreferrer">privacy policy</a>.</p>
      <Button sx={{width: "100%"}} variant="outlined" startIcon={<SvgIcon component={MetaMaskIcon} />} onClick={walletClicked} value={WalletType.MetaMask}>MetaMask</Button>
      <Button sx={{width: "100%"}} variant="outlined" startIcon={<SvgIcon component={WalletConnectIcon} />} onClick={walletClicked} value={WalletType.WalletConnect}>Wallet Connect</Button>
      <Button sx={{width: "100%"}} variant="outlined" startIcon={<SvgIcon component={CoinbaseIcon} />} onClick={walletClicked} value={WalletType.Coinbase}>Coinbase</Button>
      { showDisconnect && <Button sx={{width: "100%"}} variant="contained" onClick={walletClicked} value={WalletType.None}>Disconnect</Button> }
    </div>
  </Dialog>
  );
}