import { BigNumber, ethers } from "ethers";

export const BIG_ZERO = ethers.constants.Zero;
export const BIG_ONE = ethers.constants.One;
export const BIG_TEN = BIG_ONE.mul(10);

export enum WalletType {
  None = 'None',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  Coinbase = 'Coinbase',
}

export interface TokenInfo {
  readonly label: string,
  readonly address: string,
  readonly decimals: number,
  price: BigNumber,
}

export function initTokenInfo(options?: Partial<TokenInfo>): TokenInfo {
  const defaults = {
    label: '',
    address: '',
    decimals: 0,
    price: BIG_ZERO,
  };

  return {
    ...defaults,
    ...options,
  };
}

export interface YAInfo {
  readonly name: string,
  readonly address: string,
  readonly tokenAddress: string,
  readonly rewardsAddress: string,
}

export interface VaultInfo {
  readonly name: string,
  readonly address: string,
}

export interface YAGlobalSettings {
  readonly feeAccount: string,
  readonly earningsFee: BigNumber,
}

export interface EthereumChain {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[];
}

export interface PriceData {
  prices: {
    gas: {
      fastGasPrice: string,
      proposeGasPrice: string,
      safeGasPrice: string,
      usdPrice: string,
    },
    tokens: TokenPrice[];
  }
}

export interface TokenPrice {
  price: string;
  symbol: string;
}

export interface UsdcAuth {
  owner: string;
  spender: string;
  value: BigNumber;
  validAfter: BigNumber;
  validBefore: BigNumber;
  nonce: string;
  v: string;
  r: string;
  s: string;
}

export interface VaultAuth {
  signature: string;
  nonce: string;
  deadline: BigNumber;
  amount: BigNumber;
  fap: BigNumber;
  fapIndex: BigNumber;
  depositSwaps: SwapInfo[];
  compoundSwaps: SwapInfo[];
}

export interface SwapInfo {
  srcToken: string;
  amount: BigNumber;
  data: string;
}

export function initSwapInfo(options?: Partial<SwapInfo>): SwapInfo {
  const defaults = {
    srcToken: '0x0',
    amount: BIG_ZERO,
    data: '0x',
  };

  return {
    ...defaults,
    ...options,
  };
}