import React, { useEffect, useState } from 'react';
import './App.scss';
import { PriceData, WalletType } from '../code/types';
import ConnectDiaglog from './ConnectDialog';
import { Alert, Snackbar } from '@mui/material';
import ConnectionBar from './ConnectionBar';
import GiddyLogoBlack from '../content/giddy-horizontal-black.svg';
import YAManager from './YAManager';
import useWallet from '../code/useWallet';
import { useQuery } from '@apollo/client';
import { PRICES_QUERY } from '../code/graphql';
import { BigNumber, ethers } from 'ethers';
import { appConfig, getSymbolList } from '../code/appConfig';
import Other from './Other';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopLinks from './TopLinks';
import Background from './Background';
import SingleAssetFarm from './SingleAssetFarm';

function App() {
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [connectOpen, setConnectOpen] = useState(false);
  const [walletType, setWalletType] = useState((localStorage.getItem('wallet') ?? WalletType.None) as WalletType);
  const [wallet, chainId, account] = useWallet();
  const [tokens, setTokens] = useState(appConfig.tokens);

  const pricesQuery = useQuery<PriceData>(PRICES_QUERY, { 
    variables: { 
      input: { 
        symbols: getSymbolList()
      } 
    } 
  });

  useEffect(() => {
    if (pricesQuery.data?.prices?.gas?.fastGasPrice) {
      console.log('GAS PRICE SET: ' + pricesQuery.data.prices.gas.fastGasPrice);
      appConfig.gasPrice = ethers.utils.parseUnits(pricesQuery.data.prices.gas.fastGasPrice, 'gwei');
    }
    if (pricesQuery.data?.prices?.tokens) {
      for (let i = 0; i < appConfig.tokens.length; i++) {
        for (let j = 0; j < pricesQuery.data.prices.tokens.length; j++) {
          if (pricesQuery.data.prices.tokens[j].price) {
            if (pricesQuery.data.prices.tokens[j].symbol === appConfig.tokens[i].label) {
              appConfig.tokens[i].price = BigNumber.from(pricesQuery.data.prices.tokens[j].price);
            }
          }
        }
      }
      setTokens(appConfig.tokens);
    }
  },[pricesQuery.data]);

  useEffect(() => {
    wallet.connect(walletType);
  }, [wallet, walletType]);

  function walletSelected(wallet: WalletType) {
    if (wallet) {
      localStorage.setItem('wallet', wallet);
      setWalletType(wallet);
      setConnectOpen(false);
    }
  }

  function displayError(text: string) {
    setErrorText(text);
    setErrorOpen(true);
  }

  function displaySuccess(text: string) {
    setSuccessText(text);
    setSuccessOpen(true);
  }

  return (
    <div className="app">
      <BrowserRouter>
        <div className="content">
          <div className="top-bar">
            <div className="top-bar-grid">
              <a href="https://giddy.co/"><GiddyLogoBlack /></a>
              <TopLinks />
              <ConnectionBar
                onConnect={() => { setConnectOpen(true) }} 
                walletType={walletType}
                chainId={chainId}
                account={account} />
            </div>
          </div>
          <Routes>
            <Route path="*" element={<YAManager wallet={wallet} account={account} tokens={tokens} displaySuccess={displaySuccess} displayError={displayError} />} />
            <Route path="/other" element={<Other wallet={wallet} account={account} displaySuccess={displaySuccess} displayError={displayError} />} />
            <Route path="/farm" element={<SingleAssetFarm wallet={wallet} account={account} displaySuccess={displaySuccess} displayError={displayError} />} />
          </Routes>
        </div>
        <ConnectDiaglog
          onClose={() => { setConnectOpen(false) }} 
          onSelect={walletSelected} 
          isOpen={connectOpen}
          walletType={walletType} />
        <Snackbar open={errorOpen} autoHideDuration={7000} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} onClose={() => {setErrorOpen(false)}}>
          <Alert onClose={() => {setErrorOpen(false)}} severity="error" sx={{ width: '100%' }}>
            {errorText}
          </Alert>
        </Snackbar>
        <Snackbar open={successOpen && !errorOpen} autoHideDuration={7000} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} onClose={() => {setSuccessOpen(false)}}>
          <Alert onClose={() => {setSuccessOpen(false)}} severity="success" sx={{ width: '100%' }}>
            {successText}
          </Alert>
        </Snackbar>
        <Background />
      </BrowserRouter>
    </div>
  );
}

export default App;