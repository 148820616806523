import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useRef } from 'react';
import { formatTokenMoney, formatTokenValue } from '../code/globals';
import './FormatToken.scss';

interface FormatTokenProps {
  value: BigNumber,
  decimals?: number,
  abbreviateValue?: boolean,
  showValue?: boolean,
  price?: BigNumber,
  abbreviatePrice?: boolean,
  fontSize?: any,
}

export default function FormatToken({ value, decimals, abbreviateValue, showValue, price, abbreviatePrice }: FormatTokenProps) {
  if (!decimals) decimals = 18;
  const valueElement = useRef<HTMLDivElement>(null);
  const subvalueElement = useRef<HTMLDivElement>(null);
  const lastValue = useRef(ethers.constants.MaxUint256);

  useEffect(() => {
    if (!value.eq(ethers.constants.MaxUint256)) {
      if (!lastValue.current.eq(ethers.constants.MaxUint256) && !lastValue.current.eq(value)) {
        if (valueElement.current) {
          valueElement.current.classList.remove('flash-red');
          valueElement.current.classList.remove('flash-green');
          void valueElement.current.offsetWidth;
          valueElement.current.classList.add(value.gt(lastValue.current) ? 'flash-green' : 'flash-red');
        }
        if (subvalueElement.current) {
          subvalueElement.current.classList.remove('flash-sub-red');
          subvalueElement.current.classList.remove('flash-sub-green');
          void subvalueElement.current.offsetWidth;
          subvalueElement.current.classList.add(value.gt(lastValue.current) ? 'flash-sub-green' : 'flash-sub-red');
        }
      }
      lastValue.current = value;
    }
  },[value]);

  if (price && showValue) {
    return (
      <div className="format-token" ref={valueElement}>
        { formatTokenValue(value, decimals, abbreviateValue) } &nbsp;
        <span className='format-token-subvalue' ref={subvalueElement}>
          { formatTokenMoney(value, price, decimals, abbreviatePrice) }
        </span>
      </div>
    ); 
  }
  else if (price) {
    return (
      <div className="format-token" ref={valueElement}>
        { formatTokenMoney(value, price, decimals, abbreviatePrice) }
      </div>
    );
  }
  else {
    return (
      <div className="format-token" ref={valueElement}>
        { formatTokenValue(value, decimals, abbreviateValue) }
      </div>
    );
  }
}