import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers';
import { BigNumber, ethers } from "ethers";
import { appConfig } from './appConfig';
import { EthereumChain, WalletType } from "./types";
import WalletConnectProvider from '@walletconnect/web3-provider';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

declare const window: any;

export class Wallet extends EventTarget {
  public static instance = new Wallet();
  public provider = new JsonRpcProvider(appConfig.rpcUrl);
  public walletType: WalletType = WalletType.None;
  public walletProvider: Web3Provider | null = null;

  private connectEvent: Event = new Event('connect');
  private disconnectEvent: Event = new Event('disconnect');
  private chainChangedEvent: Event = new Event('chainChanged');
  private accountChangedEvent: Event = new Event('accountChanged');

  public async connect(walletType: WalletType): Promise<string> {
    try {
      this.walletType = walletType;
      switch(walletType) {
        default:
          {
            this.disconnect();
          }
          return '';
        case WalletType.MetaMask:
          {
            if (!window?.ethereum) {
              return 'Install MetaMask browser extension';
            }
            this.walletProvider = new ethers.providers.Web3Provider(window.ethereum);
            window.ethereum.on('connect', this.handleConnect);
            window.ethereum.on('disconnect', this.handleDisconnect);
            window.ethereum.on('accountsChanged', this.handleAccountsChanged);
            window.ethereum.on('chainChanged', this.handleChainChanged);
          }
          break;
        case WalletType.WalletConnect:
          {
            const walletConnectProvider = new WalletConnectProvider({ rpc: { 31337: appConfig.network.rpcUrls[0] } });
            this.walletProvider = new ethers.providers.Web3Provider(walletConnectProvider);
  
            if (walletConnectProvider.isWalletConnect) {
              walletConnectProvider.disconnect().then(() => {
                walletConnectProvider.enable();
              }).catch (() => {
                walletConnectProvider.enable();
              });
            }
            else {
              walletConnectProvider.enable();
            }
          }
          break;
          case WalletType.Coinbase:
            {
              const coinbaseWallet = new WalletLinkConnector({
                url: appConfig.network.rpcUrls[0],
                appName: 'Giddy',
                supportedChainIds: [31337],
              });
              await coinbaseWallet.activate();
              this.walletProvider = new ethers.providers.Web3Provider(await coinbaseWallet.getProvider());
            }
            break;
      }
      console.log("DISTPATCHED");
      this.dispatchEvent(this.connectEvent);
      
      const chainID = await this.getChainId();
      if (!chainID) {
        return 'Error retrieving network from wallet';
      }
      else {
        console.log('GIDDY: Wallet on network ' + chainID);
        if (chainID !== appConfig.network.chainId) {
          console.log('GIDDY: Wrong network switching')
          if (!await this.switchChain(appConfig.network.chainId)) {
            console.log('GIDDY: Failed to switch network')
            if (!await this.addChain(appConfig.network)) {
              return 'Error adding polygon network to wallet';
            }
            else {
              console.log('GIDDY: Added network ' + appConfig.network.chainId + ' to wallet');
            }
          }
          else {
            console.log('GIDDY: Switched network to ' + appConfig.network.chainId);
          }
        }
        return '';
      }
    }
    catch (error: any) {
      return error.message;
    }
  }

  public disconnect() {
    this.walletType = WalletType.None;
    this.walletProvider = null;
    if (window?.ethereum?.removeListener) {
      window.ethereum.removeListener('connect', this.handleConnect);
      window.ethereum.removeListener('disconnect', this.handleDisconnect);
      window.ethereum.removeListener('accountsChanged', this.handleAccountsChanged);
      window.ethereum.removeListener('chainChanged', this.handleChainChanged);
    }
    this.dispatchEvent(this.disconnectEvent);
  }

  public handleConnect(connectInfo: any) {
    console.log('GIDDY.Wallet.connect: ' + JSON.stringify(connectInfo));
  }

  public handleDisconnect(error: any) {
    console.log('GIDDY.Wallet.disconnect: ' + JSON.stringify(error));
  }

  public handleChainChanged(chainId: string) {
    console.log('GIDDY.Wallet.chainChanged: ' + chainId);
    Wallet.instance.dispatchEvent(Wallet.instance.chainChangedEvent);
  }

  public handleAccountsChanged(accounts: Array<string>) {
    console.log('GIDDY.Wallet.accountChanged: ' + accounts[0]);
    Wallet.instance.dispatchEvent(Wallet.instance.accountChangedEvent);
  }

  public async getChainId(): Promise<string> {
    try {
      if (this.walletProvider) {
        const result = await this.walletProvider.send('eth_chainId', []) as string;
        return ethers.utils.hexStripZeros(result);
      }
    }
    catch (error) {
      console.error('GIDDY.Wallet.getChainId', error);
    }
    return '';
  }

  public async getBlockNumber(): Promise<number> {
    try {
      return await this.provider.getBlockNumber()
    }
    catch (error) {
      console.error('GIDDY.Wallet.getBlockNumber', error);
    }
    return 0;
  }

  public async getAccount(): Promise<string> {
    try {
      if (this.walletProvider) {
        const result = await this.walletProvider.send('eth_requestAccounts', []) as string;
        return result[0];
      }
    }
    catch (error) {
      console.error('GIDDY.Wallet.getAccount', error);
    }
    return '';
  }

  public async switchChain(chainId: string): Promise<boolean> {
    try {
      if (this.walletProvider) {
        const error = await this.walletProvider.send('wallet_switchEthereumChain', [{chainId}]);
        if (error !== null) {
          console.error('GIDDY.Wallet.switchChain.result', error);
        }
        else {
          return true;
        }
      }
    }
    catch (error) {
      console.error('GIDDY.Wallet.switchChain', error);
    }
    return false;
  }

  public async addChain(chain: EthereumChain): Promise<boolean> {
    try {
      if (this.walletProvider) {
        const error = await this.walletProvider.send('wallet_addEthereumChain', [chain]);
        if (error !== null) {
          console.error('GIDDY.Wallet.addChain.result', error);
        }
        else {
          return true;
        }
      }
    }
    catch (error) {
      console.error('GIDDY.Wallet.addChain', error);
    }
    return false;
  }

  public async waitForTransactionStatus(hash: string, confirms: number, waitTime: number, retries: number): Promise<boolean> {
    try {
      for (let i = 0; i < retries + 1; i++) {
        try { await this.provider.waitForTransaction(hash, confirms, waitTime === -1 ? undefined : waitTime); } catch {}
        const receipt = await this.provider.getTransactionReceipt(hash);
        if (receipt) {
          return receipt.status === 1;
        }
      }
    }
    catch (error: any) {
      console.error('GIDDY.Wallet.waitForTransactionStatus', error);
    }
    return false;
  }

  public async generateSignedAuthUSDC(contractAddress: string, amount: BigNumber): Promise<string> {
    try {
      const data = {
        message: {
          owner:  await this.walletProvider?.getSigner().getAddress(),
          spender: contractAddress,
          value: amount.toString(),
          validAfter: "0",
          validBefore: Math.floor(Date.now() / 1000) + 3600,
          nonce: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
        },
        domain: {
          name: "USD Coin (PoS)",
          version: "1",
          verifyingContract: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
          salt: "0x0000000000000000000000000000000000000000000000000000000000000089",
        },
        primaryType: "ApproveWithAuthorization",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "verifyingContract", type: "address" },
            { name: "salt", type: "bytes32" },
          ],
          ApproveWithAuthorization: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "validAfter", type: "uint256" },
            { name: "validBefore", type: "uint256" },
            { name: "nonce", type: "bytes32" },
          ],
        },
      };
  
      const params = [data.message.owner, JSON.stringify(data)];
      const signature = await window.ethereum.request({method: "eth_signTypedData_v4", params, from: data.message.owner});
      const v = "0x" + signature.slice(130, 132);
      const r = signature.slice(0, 66);
      const s = "0x" + signature.slice(66, 130);
    
      const encodedFunctionParams = ethers.utils.defaultAbiCoder.encode(
        [
          "address",
          "address",
          "uint256",
          "uint256",
          "uint256",
          "bytes32",
          "uint8",
          "bytes32",
          "bytes32",
        ],
        [
          data.message.owner,
          data.message.spender,
          data.message.value,
          data.message.validAfter,
          data.message.validBefore,
          data.message.nonce,
          v,
          r,
          s,
        ]
      );
      return encodedFunctionParams;
    }
    catch (error) {
      console.error('GIDDY.Wallet.generateSignedAuthUSDC', error);
    }
    return '';
  }

  public async generateSignedAuthGiddy(contractAddress: string, amount: BigNumber, allowance: BigNumber) {
    try {
      const account = await this.walletProvider?.getSigner().getAddress();

      const message = {
        owner: account,
        spender: contractAddress,
        value: amount.toString(),
        deadline: (Math.floor(Date.now() / 1000) + 500).toString(),
        nonce: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
        currentApproval: allowance.toString(),
      };

      const data = {
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          ApproveWithAuthorization: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "deadline", type: "uint256" },
            { name: "nonce", type: "bytes32" },
            { name: "currentApproval", type: "uint256" },
          ],
        },
        domain: {
          name: 'Giddy Token',
          version: "1.0",
          verifyingContract: appConfig.giddy,
          chainId: appConfig.network.chainId,
        },
        primaryType: "ApproveWithAuthorization",
        message: message,
      };

      const params = [
        message.owner,
        JSON.stringify(data),
      ]

      const signature = await window.ethereum.request({method: "eth_signTypedData_v4", params, from: message.owner})

      const approvalRequest = {
        owner: message.owner,
        spender: message.spender,
        value: message.value,
        deadline: message.deadline,
        nonce: message.nonce,
        currentApproval: message.currentApproval,
      };
    
      return ['', approvalRequest, signature];
    }
    catch (error: any) {
      console.error('GIDDY.Wallet.generateSignedAuthGiddy', error);
      return [error.message, null, null];
    }
  }

  public async generateSignedTransfer(recipient: string, nonce: string, fap: BigNumber) {
    try {
      const account = await this.walletProvider?.getSigner().getAddress();
      const message = {
        owner: account,
        recipient: recipient,
        fap: fap.toString(),
        deadline: (Math.floor(Date.now() / 1000) + 500).toString(),
        nonce: nonce,
      };

      const data = {
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          TransferRequest: [
            { name: "owner", type: "address" },
            { name: "recipient", type: "address" },
            { name: "fap", type: "uint256" },
            { name: "deadline", type: "uint256" },
            { name: "nonce", type: "bytes32" },
          ],
        },
        domain: {
          name: 'Giddy Token',
          version: "1.0",
          verifyingContract: appConfig.giddy,
          chainId: appConfig.network.chainId,
        },
        primaryType: "TransferRequest",
        message: message,
      };

      const params = [
        message.owner,
        JSON.stringify(data),
      ]
      const signature = await window.ethereum.request({method: "eth_signTypedData_v4", params, from: message.owner})
    
      return ['', message, signature];
    }
    catch (error: any) {
      console.error('GIDDY.Wallet.generateSignedTransfer', error);
      return [error.message, null, null];
    }
  }
}