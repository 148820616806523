import { useState, useEffect } from 'react';
import { Wallet } from './Wallet';

function useWallet(): [Wallet, string, string] {
  const [chainId, setChainId] = useState('');
  const [account, setAccount] = useState('');

  useEffect(() => {
    const accountChanged = async () => {
      console.log('ACCOUNT CHANGED');
      setAccount(await Wallet.instance.getAccount());
    };
    Wallet.instance.addEventListener('connect', accountChanged);
    Wallet.instance.addEventListener('disconnect', accountChanged);
    Wallet.instance.addEventListener('accountChanged', accountChanged);

    const chainChanged = async () => {
      console.log('CHAIN CHANGED');
      setChainId(await Wallet.instance.getChainId());
    };
    Wallet.instance.addEventListener('connect', chainChanged);
    Wallet.instance.addEventListener('disconnect', chainChanged);
    Wallet.instance.addEventListener('chainChanged', chainChanged);
    
    return () => {
      Wallet.instance.removeEventListener('connect', accountChanged);
      Wallet.instance.removeEventListener('disconnect', accountChanged);
      Wallet.instance.removeEventListener('accountChanged', accountChanged);
      Wallet.instance.removeEventListener('connect', chainChanged);
      Wallet.instance.removeEventListener('disconnect', chainChanged);
      Wallet.instance.removeEventListener('chainChanged', chainChanged);
    }
  },[]);
  return [Wallet.instance, chainId, account];
}

export default useWallet;