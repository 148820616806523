import React, { useEffect, useRef, useState } from 'react';
import './VaultDialog.scss';
import Dialog from '@mui/material/Dialog';
import { Autocomplete, Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import { LoadingButton } from '@mui/lab';
import { formatTokenValue, parseBigNumber } from '../code/globals';
import { appConfig } from '../code/appConfig';
import CloseIcon from 'mdi-react/CloseIcon';
import { BIG_TEN, BIG_ZERO, SwapInfo, TokenInfo, UsdcAuth, initSwapInfo, initTokenInfo } from '../code/types';
import { GiddyVaultV2 } from '../code/contracts/GiddyVaultV2';
import TokenStake from './TokenStake';
import { erc20Allowance, erc20Approve } from '../code/contracts/Erc20';
import { OneInchSwapQuoteResponse, OneInchSwapResponse, oneInchQuote, oneInchSwap } from '../code/oneInchService';

interface VaultDialogProps {
  isOpen: boolean;
  account: string;
  tokens: TokenInfo[];
  agg: GiddyVaultV2 | null;
  strategy: string;
  nativeToken: TokenInfo;
  depositTokens: TokenInfo[];
  withdrawTokens: TokenInfo[];
  rewardTokens: TokenInfo[];
  userShares: BigNumber;
  displaySuccess(message: string): void;
  displayError(message: string): void;
  onClose(): void;
}

export default function VaultDialog({ isOpen, account, tokens, agg, strategy, nativeToken, depositTokens, withdrawTokens, rewardTokens, userShares, displaySuccess, displayError, onClose }: VaultDialogProps) {
  const [radioText, setRadioText] = useState('stake');
  const [loading, setLoading] = useState(false);
  const [compoundText, setCompoundText] = useState('Compound');

  const [withdrawError, setWithdrawError] = useState('');
  const [withdrawLabel, setWithdrawLabel] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [unstakeDisabled, setUnstakeDisabled] = useState(true);
  const [unstakeText, setUnstakeText] = useState('Unstake');

  const [stakeToken, setStakeToken] = useState(initTokenInfo());

  const signedAuthUsdc = useRef({} as UsdcAuth);
  const signedAuthGiddy = useRef(null as any);

  useEffect(() => {
    if (isOpen) {
      setRadioText('stake');
    }
  },[isOpen]);

  useEffect(() => {
    if (radioText) {
      setStakeToken(initTokenInfo());
    }
  },[radioText]);

  useEffect(() => {
    if (agg && withdrawTokens.length > 0 && nativeToken.decimals > 0) {
      const shares = parseBigNumber(withdrawAmount, nativeToken.decimals + 10);
      if (shares.gt(BIG_ZERO)) {
        console.log("SHARES: " + formatTokenValue(shares, 0, false));
        agg.getWithdrawAmounts(shares).then((amounts) => {
          if (withdrawAmount) {
            setWithdrawLabel(amounts.map((amount, i) => { 
              return withdrawTokens[i].label + ": " + formatTokenValue(amount, withdrawTokens[i].decimals, true);
            }).join(", "));
          }
        });
      }
    }
  }, [agg, withdrawTokens, withdrawAmount, nativeToken]);

  async function compoundClicked() {
    if (account && agg) {
      setLoading(true);
      setCompoundText('Querying');
      const swaps = await buildCompoundSwaps(true);
      setCompoundText('Confirming');
      const vaultAuth = await agg.generateSignedVaultAuth(BIG_ZERO, BIG_ZERO, 0, [], swaps);
      if (!vaultAuth.signature) {
        displayError('Compound unsuccessful, error signing vault auth');
      }
      else {
        const compoundResult = await agg.compound(vaultAuth);
        if (!ethers.utils.isHexString(compoundResult)) {
          displayError(compoundResult);
        }
        else {
          setCompoundText('Compounding');
          const compoundSuccess = await agg.wallet.waitForTransactionStatus(compoundResult, 1, 1000, 300);
          if (!compoundSuccess) {
            displayError('Compound unsuccessful, transaction failed');
          }
          else {
            displaySuccess('Giddy Up! Successfully Compounded');
            onClose();
          }
        }
      }

      setLoading(false);
      setCompoundText('Compound');
    }
  }

  function onRadioChange(event: any) {
    setRadioText(event.target.value);
  }

  function withdrawChange(value: string) {
    setWithdrawError('');
    setWithdrawLabel('');
    setUnstakeDisabled(true);
    setUnstakeText('Unstake');
    value = value.trim().replaceAll(',', '');
    if (value.startsWith('.')) value = '0' + value;
    setWithdrawAmount(value);
    if (value.length > 0) {
      const amount = parseBigNumber(value, (nativeToken.decimals ?? 0) + 10);
      if (!amount.gt(ethers.constants.Zero)) {
        setWithdrawError('Invalid Value');
      }
      else {
        if (amount.gt(userShares)) {
          setWithdrawError("Amount exceeds shares available");
        }
        else {
          setUnstakeDisabled(false);
        }
      }
    }
  }

  function withdrawMaxClicked() {
    withdrawChange(formatTokenValue(userShares, nativeToken.decimals + 10, false));
  }

  async function withdrawClicked() {
    if (agg) {
      setLoading(true);
      setUnstakeText('Pending');
      const amount = parseBigNumber(withdrawAmount, nativeToken.decimals + 10);
      if (!amount.gt(ethers.constants.Zero)) {
        displayError('Invalid amount');
      }
      else {
        const compoundSwaps = await buildCompoundSwaps(false);
        const withdrawResult = await agg.withdrawAuth(await agg.generateSignedVaultAuth(amount, BIG_ZERO, 0, [], compoundSwaps));
        if (!ethers.utils.isHexString(withdrawResult)) {
          displayError(withdrawResult);
        }
        else {
          const unstakeSuccess = await agg.wallet.waitForTransactionStatus(withdrawResult, 1, 1000, 300);
          if (!unstakeSuccess) {
            displayError('Unstake unsuccessful, transaction failed');
          }
          else {
            displaySuccess('Successfully unstaked ' + formatTokenValue(amount, nativeToken.decimals + 10) + ' Shares');
            withdrawChange("");
            onClose();
          }
        }
      }
      setUnstakeText('Unstake');
      setLoading(false);
    }
  }

  async function onApprove(token: string, amount: BigNumber): Promise<boolean> {
    let ret = false;
    if (agg) {
      setLoading(true);
      if (token === appConfig.usdc) {
        const usdcAuth = await agg.generateSignedUsdcAuth(account, amount);
        if (!usdcAuth.owner) {
          displayError('Error signing approval');
        }
        else {
          signedAuthUsdc.current = usdcAuth;
          displaySuccess('Your deposit ' + formatTokenValue(amount, 6) + ' has been approved. Stake to complete your transaction.');
          ret = true;
        }
      }
      else if (token === appConfig.giddy) {
        const currentAllowance = await erc20Allowance(appConfig.giddy, account, agg.info.address);
        const signedAuthResult = await agg.wallet.generateSignedAuthGiddy(agg.info.address, amount, currentAllowance);
        if (signedAuthResult[0]) {
          displayError('Error signing approval, ' + signedAuthResult[0]);
        }
        else {
          signedAuthGiddy.current = signedAuthResult;
          displaySuccess('Your deposit ' + formatTokenValue(amount, 18) + ' has been approved. Stake to complete your transaction.');
          ret = true;
        }
      }
      else {
        const approveResult = await erc20Approve(agg.wallet, token, agg.info.address, amount);
        if (!ethers.utils.isHexString(approveResult)) {
          displayError(approveResult);
        }
        else {
          if (!await agg.wallet.waitForTransactionStatus(approveResult, 1, 1000, 60)) {
            displayError("Failed ERC20 Approval");
          }
          else {
            displaySuccess('Your deposit has been approved. Stake to complete your transaction.');
            ret = true;
          }
        }
      }
      setLoading(false);
    }
    return ret;
  }

  async function onStake(token: string, amount: BigNumber): Promise<boolean> {
    let ret = false;
    if (agg) {
      setLoading(true);

      let fap = BIG_ZERO;
      if (token === appConfig.usdc) fap = BigNumber.from(10000);
      if (token === appConfig.giddy) fap = BIG_TEN.pow(18);
      
      let depositResult = "Unknown Error";

      const depositRatios = await agg.getDepositRatios();        
      const depositPrices = await calculatePrices(token, depositTokens, amount.sub(fap));
      const depositAmounts = calculateSwapTokenAmounts(amount.sub(fap), depositRatios, depositPrices);
      const depositSwaps = await buildSwaps(token, depositTokens, depositAmounts, agg.info.address, strategy);
      const compoundSwaps = await buildCompoundSwaps(false);
      const vaultAuth = await agg.generateSignedVaultAuth(amount, fap, 0, depositSwaps, compoundSwaps);
      if (!vaultAuth.signature) {
        depositResult = 'Error signing vault auth';
      }
      else {
        if (token === appConfig.usdc) {
          depositResult = await agg.depositUsdc(signedAuthUsdc.current, vaultAuth);
        }
        else if (token === appConfig.giddy) {
          depositResult = await agg.depositGiddy(signedAuthGiddy.current[1], signedAuthGiddy.current[2], vaultAuth);
        }
        else {
          depositResult = await agg.depositSingle(vaultAuth);
        }
      }

      if (!ethers.utils.isHexString(depositResult)) {
        displayError(depositResult);
      }
      else {
        const depositSuccess = await agg.wallet.waitForTransactionStatus(depositResult, 1, 1000, 60);
        if (!depositSuccess) {
          displayError('Stake unsuccessful, transaction failed');
        }
        else {
          ret = true;
          displaySuccess('Giddy Up! Successfully Staked');
          onClose();
        }
      }
      setLoading(false);
    }
    return ret;
  }

  async function calculatePrices(srcToken: string, dstTokens: TokenInfo[], amount: BigNumber): Promise<BigNumber[]> {
    const prices: BigNumber[] = new Array(dstTokens.length);
    for (let i = 0; i < dstTokens.length; i++) {
      if (srcToken === dstTokens[i].address) {
        prices[i] = BIG_TEN.pow(18);
      }
      else {
        console.log("QUOTE: " + srcToken + " DST: " + dstTokens[i].address + " AMOUNT: " + amount);
        const result  = await oneInchQuote<OneInchSwapQuoteResponse>(srcToken, dstTokens[i].address, amount);
        prices[i] = BigNumber.from(result.fromTokenAmount).mul(BIG_TEN.pow(result.toToken.decimals)).mul(BIG_TEN.pow(18)).div(BigNumber.from(result.toTokenAmount).mul(BIG_TEN.pow(result.fromToken.decimals)));
      }
    }
    return prices;
  }

  function calculateSwapTokenAmounts(inputAmount: BigNumber, ratios: BigNumber[], prices: BigNumber[]): BigNumber[] {
    const priceRatios = ratios.map((ratio, index) => {
      return ratio.mul(prices[index]).div(BIG_TEN.pow(18));
    });
    const total = priceRatios.reduce((accumulator, priceRatio) => {
      return accumulator.add(priceRatio);
    });
    return priceRatios.map((priceRatio) => {
      return inputAmount.mul(priceRatio).div(total);
    });
  }

  async function buildSwaps(srcToken: string, dstTokens: TokenInfo[], amounts: BigNumber[], account: string, receiver: string): Promise<SwapInfo[]> {
    const swaps: SwapInfo[] = new Array(0);
    for (let i = 0; i < dstTokens.length; i++) {
      const swap = initSwapInfo();
      swap.srcToken = srcToken;
      swap.amount = amounts[i];
      if (srcToken !== dstTokens[i].address && amounts[i].gt(BIG_ZERO)) {
        console.log("SWAP(SRC: " + srcToken.substring(0, 4) + ", DST: " + dstTokens[i].address.substring(0, 4) + ", AMOUNT:" + formatTokenValue(amounts[i], srcToken === appConfig.usdc ? 6 : 18) + ")");
        const result = await oneInchSwap<OneInchSwapResponse>(srcToken, dstTokens[i].address, amounts[i], account, receiver, 50);
        swap.data = result.tx.data;
      }
      swaps.push(swap);
    }
    return swaps;
  }

  async function buildCompoundSwaps(force: boolean): Promise<SwapInfo[]> {
    let swaps: SwapInfo[] = new Array(0);
    if (agg) {
      const amounts: BigNumber[] = new Array(0);
      const thresholds: BigNumber[] = new Array(0);
      const values = await agg.getContractRewards();
 
      for (let i = 0; i < values.length; i += 2) {
        amounts.push(values[i]);
        thresholds.push(values[i + 1]);
      }
      if (rewardTokens.length > 0 && rewardTokens.length == amounts.length) {
        const needsCompound = amounts.some((value, index) => { value.gte(thresholds[index]) });
        if (needsCompound || force) {
          const ratios = await agg.getDepositRatios();
          for (let i = 0; i < rewardTokens.length; i++) {
            if (amounts[i].gte(thresholds[i]) || force) {
              const depositPrices = await calculatePrices(rewardTokens[i].address, depositTokens, amounts[i]);
              const depositAmounts = calculateSwapTokenAmounts(amounts[i], ratios, depositPrices);
              swaps = swaps.concat(await buildSwaps(rewardTokens[i].address, depositTokens, depositAmounts, strategy, strategy));
            }
            else {
              swaps = swaps.concat(depositTokens.map(() => initSwapInfo()));
            }
          }
        }
      }
    }
    return swaps;
  }

  let radioBox = (
    <div className='stake-box'>
      <Autocomplete
        options={tokens}
        fullWidth={true}
        isOptionEqualToValue={(option, value) => option.address === value.address}
        renderInput={(params) => <TextField {...params} label="Token" />}
        onChange={(_e, value) => {
          setStakeToken(value ?? initTokenInfo() as TokenInfo);
      }}/>
      <TokenStake account={account} token={stakeToken} onApprove={onApprove} onStake={onStake} />
    </div>
  );

  if (radioText === 'unstake') {
    radioBox = (
      <div className='stake-box'>
        <div className='amount-box'>
          <TextField
            fullWidth
            label={"Shares " + withdrawLabel} 
            value={withdrawAmount} 
            onChange={(e) => withdrawChange(e.target.value)} 
            error={withdrawError.length > 0} 
            helperText={withdrawError} />
          <Button sx={{height: 56}} onClick={withdrawMaxClicked}>Max</Button>
        </div>
        <LoadingButton 
          variant="contained" 
          fullWidth 
          disabled={unstakeDisabled}
          loading={loading}
          loadingPosition="end"
          onClick={withdrawClicked}
          sx={{
            ':disabled': {
              'background': '#E2DFD9',
              'color': '#FFF'
            }
          }}
          >
          {unstakeText}
        </LoadingButton>
      </div>
    );
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => { onClose(); }} open={isOpen}>
      <IconButton onClick={() => { onClose(); }} sx={{ position: 'absolute', right: 8, top: 8, }}>
        <CloseIcon />
      </IconButton>
      <div className="farm-dialog">
        <div>
          <a href={'https://polygonscan.com/address/' + agg?.info.address} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
            {agg?.info.name}
          </a>
        </div>
        <LoadingButton 
          className="compound" 
          variant="outlined"
          loading={compoundText !== 'Compound'}
          loadingPosition="end"
          fullWidth
          onClick={compoundClicked}>
          {compoundText}
        </LoadingButton>
        <RadioGroup row onChange={onRadioChange} defaultValue="stake">
          <FormControlLabel value="stake" control={<Radio />} label="Stake" />
          <FormControlLabel value="unstake" control={<Radio />} label="Unstake" />
        </RadioGroup>
        {radioBox}
      </div>
    </Dialog>
  );
}
