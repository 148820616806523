import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { appConfig } from './appConfig';

const httpLink = createHttpLink({
  uri: appConfig.graphqlUrl,
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export const PRICES_QUERY = gql`
query PRICES_QUERY($input: PricesInput) {
  prices(input: $input) {
    gas {
      fastGasPrice
      proposeGasPrice
      safeGasPrice
      usdPrice
    }
    tokens {
      price
      symbol
    }
  }
}
`;