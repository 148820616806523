import { ethers } from "ethers";
import { EthereumChain, TokenInfo, VaultInfo, YAInfo } from "./types";

/* export const thunderfuryConfig = {
  gasPrice: ethers.utils.parseUnits('55', 'gwei'),
  graphqlUrl: 'https://chefapi.giddystaging.com/g',
  rpcUrl: 'https://thunderfury.giddystaging.com',
  oneInchUrl: 'https://api-giddy.1inch.io/v5.0/137/',
  usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  giddy: "0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6",
  wMatic: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  sushiSwapRouter: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  paperHands: "0x572095013B5eA69E57242E371a1a594Ff68e9EcA",
  giddyConfig: "0x7D1a307dA8928C0086CC9b2c8a7f447e25e9BED4",
  testContract: "0xEeC6Fa4C83Ca843EAF4eeEeE00491Bb2e1cEd9Fc",
  tokens: [
    {
      label: 'GIDDY',
      address: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      decimals: 18
    },
    {
      label: 'USDC',
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6
    },
    {
      label: 'GIDDY-USDC',
      address: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      decimals: 18
    },
    {
      label: 'QUICK[old]',
      address: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
      decimals: 18
    },
    {
      label: 'WMATIC',
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18
    },
    {
      label: 'BAL',
      address: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      decimals: 18
    },
    {
      label: 'SUSHI',
      address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
      decimals: 18
    },
    {
      label: 'CRV',
      address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      decimals: 18
    },
    {
      label: 'crvUSDBTCETH',
      address: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
      decimals: 18
    },
    {
      label: 'crvEURTUSD',
      address: '0x600743B1d8A96438bD46836fD34977a00293f6Aa',
      decimals: 18
    },
    {
      label: 'BPTC',
      address: '0x0F09F70Ed59202c77aC667f574A5f79bC65CeA48',
      decimals: 18
    },
    {
      label: 'B-stMATIC-STABLE-gauge-V2',
      address: '0x2Aa6fB79EfE19A3fcE71c46AE48EFc16372ED6dD',
      decimals: 18
    },
    {
      label: 'B-MaticX-STABLE-V2',
      address: '0xdFFe97094394680362Ec9706a759eB9366d804C2',
      decimals: 18
    },
    {
      label: 'bb-am-usd',
      address: '0x48e6B98ef6329f8f0A30eBB8c7C960330d648085',
      decimals: 18
    },
    {
      label: 'DFYN',
      address: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
      decimals: 18
    },
    {
      label: 'GNS',
      address: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
      decimals: 18
    },
    {
      label: 'DAI',
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      decimals: 18
    },
    {
      symbol: 'MAI',
      address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
      decimals: 18
    },
    {
      label: 'aUSDC-WETH',
      address: '0x3Cc20A6795c4b57d9817399F68E83e71C8626580',
      decimals: 18
    },
    {
      label: 'aUSDC-MAI',
      address: '0x25B186eEd64ca5FDD1bc33fc4CFfd6d34069BAec',
      decimals: 18
    },
    {
      label: 'aUSDC-GIDDY',
      address: '0x1DDAe2e33C1d68211C5EAE05948FD298e72C541A',
      decimals: 18
    },
  ] as TokenInfo[],
  aggregators: [
    {
      name: 'Gamma USDC WETH Narrow',
      address: '0xB295b4C60D0F9e0C9F0C7B52d84A84A89bf7eD15',
      tokenAddress: '0x3Cc20A6795c4b57d9817399F68E83e71C8626580',
      rewardsAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
    },
    { 
      name: 'Gamma USDC MAI',
      address: '0x6CD2aF539235ef982C73B2568c9dc2aC7A98307A',
      tokenAddress: '0x25B186eEd64ca5FDD1bc33fc4CFfd6d34069BAec',
      rewardsAddress: '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    }
  ] as YAInfo[],
  vaults: [
    {
      name: 'Gamma USDC MAI V2',
      address: '0x4FfDB5509909dEb57fDa05F88228730aa8102eAa',
    }
  ] as VaultInfo[],
  network: {
    chainId: '0x539',
    chainName: 'Thunderfury',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://thunderfury.giddystaging.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  } as EthereumChain,
} */

export const polygonConfig = {
  gasPrice: ethers.utils.parseUnits('55', 'gwei'),
  graphqlUrl: 'https://chefapi.giddy.co/g',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/e62586f4e0964dc89d82777a4dd7a1ab',
  oneInchUrl: 'https://api-giddy.1inch.io/v5.0/137/',
  usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  giddy: "0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6",
  wMatic: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  sushiSwapRouter: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  paperHands: "0x572095013B5eA69E57242E371a1a594Ff68e9EcA",
  giddyFarm: "0xa41069e59C3B226155081184d583a79B1704E1c4",
  giddyConfig: "0x7D1a307dA8928C0086CC9b2c8a7f447e25e9BED4",
  testContract: "0xbfb30DE5bB6D2aA9169996Ff237e679940f61Fb9",
  tokens: [
    {
      label: 'USDC',
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6
    },
    {
      label: 'GIDDY',
      address: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      decimals: 18
    },
    {
      label: 'WMATIC',
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18
    },
    {
      label: 'WETH',
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      decimals: 18
    },
    {
      label: 'WBTC',
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      decimals: 8
    },
    {
      label: 'DAI',
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      decimals: 18
    },
    {
      label: 'USDT',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      decimals: 6
    },
    {
      label: 'MAI',
      address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
      decimals: 18
    },
    {
      label: 'DFYN',
      address: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
      decimals: 18
    },
    {
      label: 'QUICK[old]',
      address: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
      decimals: 18
    },
    {
      label: 'QUICK',
      address: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      decimals: 18
    },
    {
      label: 'MaticX',
      address: '0xfa68FB4628DFF1028CFEc22b4162FCcd0d45efb6',
      decimals: 18
    },
    {
      label: 'GIDDY-USDC',
      address: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      decimals: 18
    },
    {
      label: 'BAL',
      address: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      decimals: 18
    },
    {
      label: 'SUSHI',
      address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
      decimals: 18
    },
    {
      label: 'BPTC',
      address: '0x0F09F70Ed59202c77aC667f574A5f79bC65CeA48',
      decimals: 18
    },
    {
      label: 'CRV',
      address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      decimals: 18
    },
    {
      label: 'GNS',
      address: '0xE5417Af564e4bFDA1c483642db72007871397896',
      decimals: 18
    },
    {
      label: 'crvUSDBTCETH',
      address: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
      decimals: 18
    },
    {
      label: 'crvEURTUSD',
      address: '0x600743B1d8A96438bD46836fD34977a00293f6Aa',
      decimals: 18
    },
    {
      label: 'B-stMATIC-STABLE-gauge-V2',
      address: '0x2Aa6fB79EfE19A3fcE71c46AE48EFc16372ED6dD',
      decimals: 18
    },
    {
      label: 'B-MaticX-STABLE-V2',
      address: '0xdFFe97094394680362Ec9706a759eB9366d804C2',
      decimals: 18
    },
    {
      label: 'bb-am-usd',
      address: '0x48e6B98ef6329f8f0A30eBB8c7C960330d648085',
      decimals: 18
    },
    {
      label: 'aUSDC-WETH',
      address: '0x3Cc20A6795c4b57d9817399F68E83e71C8626580',
      decimals: 18
    },
    {
      label: 'aUSDC-MAI',
      address: '0x25B186eEd64ca5FDD1bc33fc4CFfd6d34069BAec',
      decimals: 18
    },
    {
      label: 'aUSDC-GIDDY',
      address: '0x1DDAe2e33C1d68211C5EAE05948FD298e72C541A',
      decimals: 18
    },
    {
      label: 'aGDDY-WETH',
      address: '0x2D08b4B4C74d0B2f4144aE7bd86eE40FB654acef',
      decimals: 18
    },
    {
      label: 'aWBTC-GDDY',
      address: '0xCbb7FaE80e4F5c0CbFE1Af7bb1f19692f9532Cfa',
      decimals: 18
    },
    {
      label: 'aUSDC-USDT',
      address: '0x795f8c9B0A0Da9Cd8dea65Fc10f9B57AbC532E58',
      decimals: 18
    },
    {
      label: 'aUSDC-QUICK',
      address: '0xa1C3E15B3307b04067E843d3BFAF3cEAD5B51CB7',
      decimals: 18
    },
    {
      label: 'aWMATIC-GNS',
      address: '0x688cb9492bd2C72016f1765d813B2D713aa1F4C7',
      decimals: 18
    },
    {
      label: 'aWMATIC-USDC-WIDE',
      address: '0x4A83253e88e77E8d518638974530d0cBbbF3b675',
      decimals: 18
    },
    {
      label: 'aWMATIC-USDC-NARROW',
      address: '0x04d521E2c414E6d898c6F2599FdD863Edf49e247',
      decimals: 18
    },
    {
      label: 'aWMATIC-USDT',
      address: '0x598cA33b7F5FAB560ddC8E76D94A4b4AA52566d7',
      decimals: 18
    },
    {
      label: 'aUSDC-DAI',
      address: '0x9E31214Db6931727B7d63a0D2b6236DB455c0965',
      decimals: 18
    },
    {
      label: 'aWBTC-WETH',
      address: '0x4B9e26a02121a1C541403a611b542965Bd4b68Ce',
      decimals: 18
    },
    {
      label: 'aWBTC-USDC',
      address: '0x3f35705479d9d77c619b2aAC9dd7a64e57151506',
      decimals: 18
    },
    {
      label: 'aWMATIC-MATICX',
      address: '0x8dd3BF71eF18dd88869d128BDE058C9d8c270176',
      decimals: 18
    },
  ] as TokenInfo[],
  aggregators: [
    { 
      name: 'Giddy 10x Pool', 
      address: '0x7057A9c3F6514Bd034eB9326cE945c6b613E90Ea',
      tokenAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
    },
    { 
      name: 'Giddy LP 40x Pool',
      address: '0xe334DFc70819c7e72a8a59D2c27b64b2014F4Ed4',
      tokenAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
    },
    { 
      name: 'Quick Dragons Lair', 
      address: '0x91CFa70a1a124955F32A8f14B8fbDDc6bd46a89e',
      tokenAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
      rewardsAddress: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
    },
    { 
      name: 'New Quick Dragons Lair', 
      address: '0xe638E3261fd7Dad45dD0250d13611D7Fc63DdFDD',
      tokenAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
      rewardsAddress: '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
    },
    { 
      name: 'Aave Usdc', 
      address: '0xEcd55c090Ef6B63074f911D51005a65E1b8B1079',
      tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      rewardsAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    },
    { 
      name: 'Aave wMatic', 
      address: '0x9aDbc84B9d6F810FCA43079FC1A0dDfBB56c59dd',
      tokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      rewardsAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    { 
      name: 'AAVE Balancer',
      address: '0xf1872155E88255093d2Aec96a1a3ce30155e6D89',
      tokenAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    },
    { 
      name: 'AAVE Sushi V3',
      address: '0x995671e471f7a5b6f205668048A665B73ef71F9C',
      tokenAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
      rewardsAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    },
    { 
      name: 'AAVE Curve V3',
      address: '0xc7A8863992316524c4Bef75223EdC32c8dF96C23',
      tokenAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      rewardsAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    },
    { 
      name: 'Curve aTriCrypto3', 
      address: '0xaC037312c959431e890b2fa47A7332d2F5f59ECB',
      tokenAddress: '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3',
      rewardsAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    },
    { 
      name: 'Curve Eurt Usd', 
      address: '0xB529E51eFC843ABf902c3E95b7BB9b979D1247f2',
      tokenAddress: '0x600743B1d8A96438bD46836fD34977a00293f6Aa',
      rewardsAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    },
    { 
      name: 'Bifi Maxi', 
      address: '0x9ae4b5F37aAB2915e2eFDEa185e5137341E0C5ed',
      tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
      rewardsAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    { 
      name: 'Balancer WETH USDC WBTC',
      address: '0x28C3b1f02ba8F5017cd32D57db5a6E120Da44427',
      tokenAddress: '0x0F09F70Ed59202c77aC667f574A5f79bC65CeA48',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    },
    { 
      name: 'Balancer Staked Matic',
      address: '0x4011305ddC5aefC61802fF52cA52711B956b3924',
      tokenAddress: '0x2Aa6fB79EfE19A3fcE71c46AE48EFc16372ED6dD',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    },
    { 
      name: 'Balancer MaticX',
      address: '0x1767a72C8b49Bf88C3874C8Ce12351E03296EB98',
      tokenAddress: '0xdFFe97094394680362Ec9706a759eB9366d804C2',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    },
    { 
      name: 'Balancer Boosted Aave USD',
      address: '0xCBB41Dd94845cE0f5Da35797F3E02abbdC68dCd9',
      tokenAddress: '0x48e6B98ef6329f8f0A30eBB8c7C960330d648085',
      rewardsAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    },
    { 
      name: 'DFYN',
      address: '0x7F8fB5b1F21E440e6e8394C2f4a95fa963da5e92',
      tokenAddress: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
      rewardsAddress: '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
    },
    { 
      name: 'Sushi USDC ETH LP',
      address: '0xF055388463FDB517A68Ee0fa395C428Daef8b9b8',
      tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
      rewardsAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    },
    { 
      name: 'Sushi USDC GIDDY LP',
      address: '0xFCf3a8e51F764ebBc352C89ED5186176cFA7B35f',
      tokenAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
      rewardsAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
    },
    { 
      name: 'Gains GNS YA',
      address: '0xfdb9Ac89981eb33815321bB51e07b20735BB5dF9',
      tokenAddress: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
      rewardsAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    },
    { 
      name: 'Gamma USDC GIDDY',
      address: '0x4d5FE46b94B14E18f66EA8da734099Ee8471fFB3',
      tokenAddress: '0x1DDAe2e33C1d68211C5EAE05948FD298e72C541A',
      rewardsAddress: '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
    }
  ] as YAInfo[],
  vaults: [
    {
      name: 'GammaUsdcWethNarrowV2',
      address: '0xfF5320c12551F8706BC861c2B9a5C497f3ea4386',
    },
    {
      name: 'GammaGiddyWeth',
      address: '0x9DbB7d0b8D1B6aFc3Db729bBD64EaeEe06bccA18',
    },
    {
      name: 'GammaWbtcGiddy',
      address: '0x32a1459F7D9F1299046d749b1E38BbC34d880877',
    },
    {
      name: 'GammaUsdcUsdt',
      address: '0x610595Fa2D8A31A9F99A66918FD571C48a6059A3',
    },
    {
      name: 'GammaUsdcQuickNarrow',
      address: '0x5eE067a52D75775B7b3Bab76F8541B27272f406B',
    },
    {
      name: 'GammaWmaticGnsNarrow',
      address: '0x3B70927f4B6d75c8fD9933D08946CF695c70B2d9',
    },
    {
      name: 'GammaWmaticUsdcWide',
      address: '0x274A876d16e624B6a52449d487Bf87C21Ca61b2B',
    },
    {
      name: 'GammaWmaticUsdcNarrow',
      address: '0x3852Fe50a1E3Ab734A36c196C1804f876a5e6ea1',
    },
    {
      name: 'GammaWmaticUsdtNarrow',
      address: '0xE7329e7A23eD11e3AAc51C4465f3DFF3BfDAAdb6',
    },
    {
      name: 'GammaUsdcDai',
      address: '0xF4b2134BB2D41039cb2c839F8688B30A8C0B4014',
    },
    {
      name: 'GammaWbtcWethNarrow',
      address: '0xDA2C6D9De6Bf942e20a76B82cbc3fB75aB1ccA29',
    },
    {
      name: 'GammaWbtcUsdcNarrow',
      address: '0x314c101017756873ba11e9abD8347D3391CC7612',
    },
    {
      name: 'GammaWmaticMaticx',
      address: '0xc65b6ABFc70a9830131d472Ef2E1917dA2ad5162',
    },
  ] as VaultInfo[],
  network: {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-rpc.com/',
      'https://rpc-mainnet.matic.network',
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com'],
    blockExplorerUrls: ['https://polygonscan.com/']
  } as EthereumChain,
}

export function getSymbolList(): string[] {
  const ret = [] as string[];
  appConfig.tokens.map((token) => {
    ret.push(token.label);
  });
  return ret;
}

export const appConfig = polygonConfig;