import { appConfig } from "../appConfig";
import ABI from "./GiddyConfig.json";
import { BigNumber, ethers } from "ethers";
import { Wallet } from "../Wallet";

export class GiddyConfig {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;

  public wallet: Wallet;
  
  constructor(wallet: Wallet) {
    this.wallet = wallet;
    this.contract = new ethers.Contract(appConfig.giddyConfig, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async setFeeAccount(address: string): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const result = await this.connectedContract.setFeeAccount(address);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyConfig.setFeeAccount', error);
      return error.message;
    }
  }

  public async setEarningsFee(value: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const result = await this.connectedContract.setEarningsFee(value);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyConfig.setEarningsFee', error);
      return error.message;
    }
  }
}