import { appConfig } from "../appConfig";
import ABI from './GiddyFarm.json';
import { BigNumber, ethers } from "ethers";
import { Wallet } from "../Wallet";

export class GiddyFarm {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;
  private feeMultiplier = 20;

  public wallet: Wallet;
  
  constructor(wallet: Wallet) {
    this.wallet = wallet;
    this.contract = new ethers.Contract(appConfig.giddyFarm, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async rewardsPerSecond(): Promise<BigNumber> {
    try {
      return await this.contract.rewardsPerSecond();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.rewardsPerSecond', error);
    }
    return ethers.constants.Zero;
  }

  public async contractRewards(): Promise<BigNumber[]> {
    try {
      return await this.contract.contractRewards();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.getContractRewards', error);
    }
    return [];
  }

  public async apyInfo(): Promise<BigNumber[]> {
    try {
      return await this.contract.apyInfo();
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.apyInfo', error);
    }
    return [];
  }

  public async userStaked(account: string): Promise<BigNumber> {
    try {
      return await this.contract.userStaked(account);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.userStaked', error);
    }
    return ethers.constants.Zero;
  }

  public async userRewards(account: string): Promise<BigNumber> {
    try {
      return await this.contract.userRewards(account);
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.userRewards', error);
    }
    return ethers.constants.Zero;
  }

  public async deposit(giddyAuth: any, giddySig: string, fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.deposit(giddyAuth, giddySig, fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.deposit(giddyAuth, giddySig, fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.deposit', error);
      return error.message;
    }
  }

  public async withdraw(amount: BigNumber, fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.withdraw(amount, fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.withdraw(amount, fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.withdraw', error);
      return error.message;
    }
  }

  public async harvest(fap: BigNumber): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.harvest(fap);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.harvest(fap, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.GiddyFarm.harvest', error);
      return error.message;
    }
  }
}