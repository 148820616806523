import { appConfig } from "../appConfig";
import ABI from "./PaperHands.json";
import { BigNumber, ethers } from "ethers";
import { Wallet } from "../Wallet";

export class PaperHands {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;
  private feeMultiplier = 20;

  public wallet: Wallet;
  
  constructor(wallet: Wallet) {
    this.wallet = wallet;
    this.contract = new ethers.Contract(appConfig.paperHands, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async transferUsdcCalc(value: BigNumber, fap: BigNumber, swapToGiddy: boolean): Promise<[BigNumber, BigNumber]> {
    try {
      return await this.contract.transferUsdcCalc(value, fap, swapToGiddy);
    }
    catch (error: any) {
      console.error('GIDDY.PaperHands.transferUsdcCalc', error);
    }
    return [ethers.constants.Zero, ethers.constants.Zero];
  }

  public async transferUsdc(signedAuth: string, recipient: string, fap: BigNumber, swapToGiddy: boolean): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.transferUsdc(signedAuth, recipient, fap, swapToGiddy);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.transferUsdc(signedAuth, recipient, fap, swapToGiddy, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.PaperHands.transferUsdc', error);
      return error.message;
    }
  }

  public async transferGiddyCalc(value: BigNumber, fap: BigNumber, swapToUsdc: boolean): Promise<[BigNumber, BigNumber]> {
    try {
      return await this.contract.transferGiddyCalc(value, fap, swapToUsdc);
    }
    catch (error: any) {
      console.error('GIDDY.PaperHands.transferGiddyCalc', error);
    }
    return [ethers.constants.Zero, ethers.constants.Zero];
  }

  public async transferGiddy(approvalRequest: any, signature: any, recipient: string, fap: BigNumber, swapToUsdc: boolean): Promise<string> {
    try {
      if (!this.connectedContract) return 'Not Connected';
      const gasEsitmate = await this.connectedContract.estimateGas.transferGiddy(approvalRequest, signature, recipient, fap, swapToUsdc);
      const options = { gasPrice: appConfig.gasPrice, gasLimit: gasEsitmate.mul(this.feeMultiplier).div(10) };
      const result = await this.connectedContract.transferGiddy(approvalRequest, signature, recipient, fap, swapToUsdc, options);
      return result.hash;
    }
    catch (error: any) {
      console.error('GIDDY.PaperHands.transferGiddy', error);
      return error.message;
    }
  }
}