import { BigNumber, ethers } from "ethers";
import { appConfig } from "../appConfig";
import { Wallet } from "../Wallet";
import Erc20Abi from './Erc20.json';

const provider = new ethers.providers.JsonRpcProvider(appConfig.rpcUrl);

export async function erc20Symbol(token: string): Promise<string> {
  try {
    const contract = new ethers.Contract(token, Erc20Abi, provider);
    const result = await contract.symbol();
    return result as string;
  }
  catch (error) {
    console.error('Giddy.erc20Symbol', error);
  }
  return '';
}

export async function erc20Decimals(token: string): Promise<number> {
  try {
    const contract = new ethers.Contract(token, Erc20Abi, provider);
    const result = await contract.decimals();
    return result as number;
  }
  catch (error) {
    console.error('Giddy.erc20Decimals', error);
  }
  return 0;
}

export async function erc20BalanceOf(token: string, account: string): Promise<BigNumber> {
  try {
    if (token && account) {
      const contract = new ethers.Contract(token, Erc20Abi, provider);
      const result = await contract.balanceOf(account);
      return result as BigNumber;
    }
  }
  catch (error) {
    console.error('Giddy.erc20BalanceOf', error);
  }
  return ethers.constants.Zero;
}

export async function erc20Allowance(token: string, account: string, spender: string): Promise<BigNumber> {
  try {
    const contract = new ethers.Contract(token, Erc20Abi, provider);
    const result = await contract.allowance(account, spender);
    return result as BigNumber;
  }
  catch (error) {
    console.error('Giddy.erc20Allowance', error);
  }
  return ethers.constants.Zero;
}

export async function erc20Approve(wallet: Wallet, token: string, spender: string, amount: BigNumber): Promise<string> {
  try {
    if (!wallet.walletProvider) return 'Not Connected';
    const contract = new ethers.Contract(token, Erc20Abi, wallet.walletProvider.getSigner());
    //const gasEsitmate = await contract.estimateGas.approve(spender, amount);
    //const options = { gasLimit: gasEsitmate };
    const result = await contract.approve(spender, amount);
    return result.hash;
  }
  catch (error: any) {
    console.error('Giddy.erc20Approve', error);
    return error.message;
  }
}