// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../content/header-background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-background{position:absolute;width:100%;overflow:hidden;height:700px;min-width:1300px}@media screen and (max-device-width: 600px){.app-background canvas{display:none}}.background-header-image{position:absolute;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover;display:block;height:700px;z-index:-100}.background-header{height:700px;min-width:1700px;margin:auto}", "",{"version":3,"sources":["webpack://./src/components/Background.scss"],"names":[],"mappings":"AAEA,gBACE,iBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,gBAAA,CAGF,4CAEO,uBACI,YAAA,CAAA,CAKX,yBACE,iBAAA,CACA,UAAA,CACA,wDAAA,CACA,0BAAA,CACA,qBAAA,CACA,aAAA,CACA,YAAA,CACA,YAAA,CAGF,mBACE,YAAA,CACA,gBAAA,CACA,WAAA","sourcesContent":["@use \"sass:math\";\n\n.app-background {\n  position: absolute;\n  width: 100%;\n  overflow: hidden;\n  height: 700px;\n  min-width: 1300px;\n}\n\n@media screen and (max-device-width: 600px){\n   .app-background {\n       canvas {\n           display: none;\n       }\n   }\n}\n\n.background-header-image {\n  position: absolute;\n  width: 100%;\n  background-image: url(../content/header-background.svg);\n  background-position: center;\n  background-size: cover;\n  display: block;\n  height: 700px;\n  z-index: -100;\n}\n\n.background-header {\n  height: 700px;\n  min-width: 1700px;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
