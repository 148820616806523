import React from 'react';
import './ConnectionBar.scss';
import { WalletType } from '../code/types';
import { appConfig } from '../code/appConfig';
import { Button } from '@mui/material';

interface ConnectionBarProps {
  walletType: WalletType;
  account: string;
  chainId: string;
  onConnect(): void;
}

export default function ConnectionBar({walletType, account, chainId, onConnect}:ConnectionBarProps) {
  function formatedAccount(): string {
    if (account.length > 10) {
      return account.substring(0, 5) + '...' + account.substring(account.length - 4, account.length);
    }
    return account;
  }

  if (walletType === WalletType.None) {
    return (
      <div className='connection-bar'>
        <Button variant="contained" onClick={onConnect}>Connect Wallet</Button>
      </div>
    );
  }

  if (!account) {
    return (
      <div className='connection-bar' onClick={onConnect}>
        <div className='wallet bg-info'>{walletType}</div>
        <div className='status'>Connect Wallet</div>
      </div>
    );
  }

  if (chainId !== appConfig.network.chainId) {
    return (
      <div className='connection-bar' onClick={onConnect}>
        <div className='wallet bg-error' style={{backgroundColor: '#f44336'}}>{walletType}</div>
        <div className='status'>Wrong Network</div>
      </div>
    );
  }

  return (
    <div className='connection-bar'>
      <div className='wallet bg-success' onClick={onConnect}>{walletType}</div>
      <div className='status' onClick={onConnect}>{formatedAccount()}</div>
    </div>
  );
}