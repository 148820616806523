import React, { useEffect, useRef, useState } from 'react';
import './YARow.scss';
import { Wallet } from '../code/Wallet';
import { TokenInfo, YAGlobalSettings, YAInfo, initTokenInfo } from '../code/types';
import { YieldAggregator } from '../code/contracts/YieldAggregator';
import { ethers } from 'ethers';
import { Button } from '@mui/material';
import FormatToken from './FormatToken';
import { formatTokenValue, tokenByAddress } from '../code/globals';

interface YARowProps {
  account: string;
  wallet: Wallet;
  yaInfo: YAInfo;
  tokens: TokenInfo[];
  loadCount: number;
  onClick(agg: YieldAggregator): void;
}

export default function YARow({ account, wallet, yaInfo, tokens, loadCount, onClick }: YARowProps) {
  const agg = useRef(new YieldAggregator(wallet, yaInfo));
  const [tokenInfo, setTokenInfo] = useState(initTokenInfo());
  const [rewardInfo, setRewardInfo] = useState(initTokenInfo());
  const [contractShares, setContractShares] = useState(ethers.constants.Zero);
  const [contractBalance, setContractBalance] = useState(ethers.constants.Zero);
  const [contractRewards, setContractRewards] = useState(ethers.constants.Zero);
  const [userShares, setUserShares] = useState(ethers.constants.Zero);
  const [userBalance, setUserBalance] = useState(ethers.constants.Zero);
  const [globalSettings, setGlobalSettings] = useState({ feeAccount: "",  earningsFee: ethers.constants.Zero } as YAGlobalSettings);

  useEffect(() => {
    async function loadData() {
      const results = await Promise.all([
        agg.current.getContractShares(),
        agg.current.getContractBalance(),
        agg.current.getContractRewards(),
        agg.current.getGlobalSettings(),
      ]);
      setContractShares(results[0]);
      setContractBalance(results[1]);
      setContractRewards(results[2]);
      setGlobalSettings(results[3]);
      if (account) {
        const results = await Promise.all([
          agg.current.getUserShares(account),
          agg.current.getUserBalance(account),
        ]);
        setUserShares(results[0]);
        setUserBalance(results[1]);
      }
    }
    loadData();
  }, [loadCount, account, yaInfo]);

  useEffect(() => {
    setTokenInfo(tokenByAddress(tokens, yaInfo.tokenAddress));
    setRewardInfo(tokenByAddress(tokens, yaInfo.rewardsAddress));
  },[tokens, yaInfo]);

  function rowClicked() {
    onClick(agg.current);
  }

  return (
    <div className="ya-row">
      <div>
        <Button variant="contained" onClick={rowClicked} >{yaInfo.name}</Button>
        <a className="contract-link" href={'https://polygonscan.com/address/' + yaInfo.address}>{yaInfo.address}</a>
      </div>
      <div className="ya-box">
        <div className="ya-info"><b>Total Shares:</b> <FormatToken value={contractShares} decimals={tokenInfo.decimals + 10} /></div>
        <div className="ya-info"><b>Total Value Locked ({tokenInfo.label}): </b><FormatToken value={contractBalance} decimals={tokenInfo.decimals} showValue={true} price={tokenInfo.price} /></div>
        <div className="ya-info"><b>Pending Rewards ({rewardInfo.label}):</b> <FormatToken value={contractRewards} decimals={rewardInfo.decimals} showValue={true} price={rewardInfo.price} /></div>
        <div className="ya-info"><b>User Shares:</b> <FormatToken value={userShares} decimals={tokenInfo.decimals + 10} /></div>
        <div className="ya-info"><b>User Staked ({tokenInfo.label}):</b> <FormatToken value={userBalance} decimals={tokenInfo.decimals} showValue={true} price={tokenInfo.price} /></div>
        <div className="ya-info"><b>Fee Account:</b> { globalSettings.feeAccount }</div>   
        <div className="ya-info"><b>Earnings Fee:</b> { formatTokenValue(globalSettings.earningsFee, 4, false) }</div>
      </div>
    </div>
  );
}
