import React from 'react';
import './Background.scss';
import backgroundAnimation from '../content/dapp_bg_animated.riv'
import { Alignment, Fit, Layout, LayoutParameters, useRive, UseRiveParameters } from 'rive-react';

export default function Background() {

  const layoutParams: LayoutParameters = {
    fit: Fit.FitWidth,
    alignment: Alignment.Center
  }
  const riveParams: UseRiveParameters = {
    src: backgroundAnimation,
    autoplay: true,
    layout: new Layout(layoutParams)
  }
  const { RiveComponent } = useRive(riveParams);

  return (
    <div className='app-background'>
      <div className="background-header-image" />
      <RiveComponent className='background-header' />
    </div>
  );
}