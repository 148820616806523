import React from 'react';
import './TopLinks.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export default function TopLinks() {
  const navigate = useNavigate();
  
  return (
    <div className='top-links'>
      <Button variant="text" onClick={() => { return navigate("/aggregators"); }}>Aggregators</Button>
      <Button variant="text" onClick={() => { return navigate("/farm"); }}>Farm</Button>
      <Button variant="text" onClick={() => { return navigate("/other"); }}>Other</Button>
    </div>
  );
}