import { appConfig } from "../appConfig";
import ABI from './TestContract.json';
import { BigNumber, ethers } from "ethers";
import { Wallet } from "../Wallet";

export class TestContract {
  private contract: ethers.Contract;
  private connectedContract: ethers.Contract | null;
  private feeMultiplier = 20;

  public wallet: Wallet;
  
  constructor(wallet: Wallet) {
    this.wallet = wallet;
    this.contract = new ethers.Contract(appConfig.testContract, ABI.abi, this.wallet.provider);
    this.connectedContract = null;
    this.wallet.addEventListener('connect', () => this.walletConnect());
    this.wallet.addEventListener('disconnect', () => this.walletDisconnect());
  }

  private walletConnect() {
    if (this.wallet?.walletProvider != null) {
      this.connectedContract = this.contract.connect(this.wallet.walletProvider.getSigner());
    }
  }

  private walletDisconnect() {
    this.connectedContract = null;
  }

  public async testValidate(auth: any, sig: any): Promise<string> {
    try {
      return await this.contract.testValidate(auth, sig);
    }
    catch (error: any) {
      console.error('GIDDY.TestContract.testValidate', error);
    }
    return '';
  }

  public async testSigned(data: string) {
    try {
      if (this.connectedContract) {
        const domain = {
          name: "TestContract",
          version: '1.0',
          chainId: appConfig.network.chainId,
          verifyingContract: this.connectedContract.address,
        };
  
        const types = {
          TestStruct: [
            { name: "nonce", type: "bytes32" },
            { name: "deadline", type: "uint256" },
            { name: "value", type: "uint256" },
            { name: "fap", type: "uint256" },
            { name: "fapIndex", type: "uint256" },
            { name: "data", type: "bytes[]" },
          ],
        };
  
        const value = {
          nonce: ethers.utils.hexlify(ethers.utils.randomBytes(32)),
          deadline: BigNumber.from(Math.floor(Date.now() / 1000) + 500),
          value: BigNumber.from("1000"),
          fap: BigNumber.from("0"),
          fapIndex: BigNumber.from("5"),
          data: ["0x", data, data],
        }
  
        const signerAny: any = this.connectedContract.signer;
        const signature: string = await signerAny._signTypedData(domain, types, value);
        console.log("TEST VALIDATE: " + await this.testValidate(value, signature));
      }
    }
    catch (error: any) {
      console.error('GIDDY.TestContract.generateSignedTest', error);
    }
  }
}