import React, { useEffect, useRef, useState } from 'react';
import './VaultRow.scss';
import { Wallet } from '../code/Wallet';
import { BIG_ZERO, TokenInfo, VaultInfo, YAGlobalSettings, initTokenInfo } from '../code/types';
import { BigNumber, ethers } from 'ethers';
import { Button } from '@mui/material';
import FormatToken from './FormatToken';
import { formatTokenValue, tokenByAddress } from '../code/globals';
import { GiddyVaultV2 } from '../code/contracts/GiddyVaultV2';
import VaultDialog from './VaultDialog';

interface VaultRowProps {
  account: string;
  wallet: Wallet;
  vaultInfo: VaultInfo;
  tokens: TokenInfo[];
  displaySuccess(message: string): void;
  displayError(message: string): void;
}

export default function VaultRow({ account, wallet, vaultInfo, tokens, displaySuccess, displayError }: VaultRowProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadCount, setLoadCount] = useState(0);
  const [depositAddresses, setDepsoitAddresses] = useState(Array(0) as string[]);
  const [rewardAddresses, setRewardAddresses] = useState(Array(0) as string[]);
  const [withdrawAddresses, setWithdrawAddresses] = useState(Array(0) as string[]);
  const [nativeAddress, setNativeAddress] = useState('');

  const [strategy, setStrategy] = useState('');
  const [nativeToken, setNativeToken] = useState(initTokenInfo());
  const [depositTokens, setDepositTokens] = useState(Array(0) as TokenInfo[]);
  const [withdrawTokens, setWithdrawTokens] = useState(Array(0) as TokenInfo[]);
  const [rewardTokens, setRewardTokens] = useState(Array(0) as TokenInfo[]);
  const [depositRatios, setDepositRatios] = useState(Array(0) as BigNumber[]);

  const agg = useRef(new GiddyVaultV2(wallet, vaultInfo));
  const [contractShares, setContractShares] = useState(ethers.constants.Zero);
  const [contractBalance, setContractBalance] = useState(ethers.constants.Zero);
  const [contractRewards, setContractRewards] = useState(Array(0) as BigNumber[]);
  const [globalSettings, setGlobalSettings] = useState({ feeAccount: "",  earningsFee: BIG_ZERO } as YAGlobalSettings);
  const [userShares, setUserShares] = useState(ethers.constants.Zero);
  const [userBalance, setUserBalance] = useState(ethers.constants.Zero);

  useEffect(() => {
    agg.current.getStrategy().then((value) => {
      setStrategy(value);
    });
    agg.current.getNativeToken().then((value) => {
      setNativeAddress(value);
    });
    agg.current.getDepositTokens().then((value) => {
      setDepsoitAddresses(value);
    });
    agg.current.getRewardTokens().then((value) => {
      setRewardAddresses(value);
    });
    agg.current.getWithdrawTokens().then((value) => {
      setWithdrawAddresses(value);
    });
    agg.current.getDepositRatios().then((value) => {
      setDepositRatios(value);
    });
  },[]);

  useEffect(() => {
    setDepositTokens(Array.from(depositAddresses, x => tokenByAddress(tokens, x)));
  }, [depositAddresses, tokens]);

  useEffect(() => {
    setRewardTokens(Array.from(rewardAddresses, x => tokenByAddress(tokens, x)));
  }, [rewardAddresses, tokens]);

  useEffect(() => {
    setWithdrawTokens(Array.from(withdrawAddresses, x => tokenByAddress(tokens, x)));
  }, [withdrawAddresses, tokens]);

  useEffect(() => {
    setNativeToken(tokenByAddress(tokens, nativeAddress));
  }, [nativeAddress, tokens]);


  useEffect(() => {
    async function loadData() {
      const results = await Promise.all([
        agg.current.getContractShares(),
        agg.current.getContractBalance(),
        agg.current.getContractRewards(),
        agg.current.getGlobalSettings(),
      ]);
      setContractShares(results[0]);
      setContractBalance(results[1]);
      setContractRewards(results[2]);
      setGlobalSettings(results[3]);
      if (account) {
        const results = await Promise.all([
          agg.current.getUserShares(account),
          agg.current.getUserBalance(account),
        ]);
        setUserShares(results[0]);
        setUserBalance(results[1]);
      }
    }
    loadData();
  }, [loadCount, account, vaultInfo]);

  function dialogClose() {
    setDialogOpen(false);
    setLoadCount(() => loadCount + 1);
  }

  function getPendingRewards() {
    const ret = Array(0);
    if (rewardTokens.length == contractRewards.length / 2) {
      for (let i = 0; i < contractRewards.length; i += 2) {
        ret.push(<span key={i}>{rewardTokens[i / 2].label} <FormatToken value={contractRewards[i]} decimals={rewardTokens[i / 2].decimals} showValue={true} price={rewardTokens[i / 2].price} /> </span>);
      }
    }
    return ret;
  }

  function getThresholds() {
    const ret = Array(0);
    if (rewardTokens.length == contractRewards.length / 2) {
      for (let i = 0; i < contractRewards.length; i += 2) {
        ret.push(<span key={i}>{rewardTokens[i / 2].label} <FormatToken value={contractRewards[i + 1]} decimals={rewardTokens[i / 2].decimals} showValue={true} price={rewardTokens[i / 2].price} /> </span>);
      }
    }
    return ret;
  }

  return (
    <div className="ya-row">
      <div>
        <Button variant="contained" onClick={() => { setDialogOpen(true); }} >{vaultInfo.name}</Button>
        <a className="contract-link" href={'https://polygonscan.com/address/' + vaultInfo.address}>{vaultInfo.address}</a>
      </div>
      <div className="ya-box">
        <div className="ya-info"><b>Total Shares:</b> <FormatToken value={contractShares} decimals={nativeToken.decimals + 10} /></div>
        <div className="ya-info"><b>Total Value Locked:</b> { nativeToken.label } <FormatToken value={contractBalance} decimals={nativeToken.decimals} showValue={true} price={nativeToken.price} /></div>
        <div className="ya-info"><b>User Shares:</b> <FormatToken value={userShares} decimals={nativeToken.decimals + 10} /></div>
        <div className="ya-info"><b>User Balance:</b> { nativeToken.label } <FormatToken value={userBalance} decimals={nativeToken.decimals} showValue={true} price={nativeToken.price} /></div>
        <div className="ya-info"><b>Pending Rewards:</b> { getPendingRewards() }</div>
        <div className="ya-info"><b>Thresholds:</b> { getThresholds() }</div>
        <div className="ya-info"><b>Deposit Tokens:</b> { depositTokens.map((token) => { return token.label; }).join(", ") }</div>
        <div className="ya-info"><b>Deposit Ratios:</b> { depositRatios.map((ratio) => { return formatTokenValue(ratio, 18, false) }).join(", ") }</div>     
        <div className="ya-info"><b>Withdraw Tokens:</b> { withdrawTokens.map((token) => { return token.label; }).join(", ") }</div>  
        <div className="ya-info"><b>Fee Account:</b> { globalSettings.feeAccount }</div>   
        <div className="ya-info"><b>Earnings Fee:</b> { formatTokenValue(globalSettings.earningsFee, 4, false) }</div>
      </div>
      <VaultDialog 
        isOpen={dialogOpen} 
        account={account} 
        tokens={tokens} 
        agg={agg.current} 
        strategy={strategy} 
        nativeToken={nativeToken} 
        depositTokens={depositTokens} 
        withdrawTokens={withdrawTokens} 
        rewardTokens={rewardTokens}
        userShares={userShares} 
        displaySuccess={displaySuccess} 
        displayError={displayError} 
        onClose={dialogClose} />
    </div>
  );
}
